import React from 'react';

import ScrollAnimation from 'react-animate-on-scroll';

import UserAvatarIcon from 'assets/icons/user-avatar-filled.svg';
import SkillTreeImage from 'assets/images/skill-tree.png';

import classes from './SkillTree.module.scss';

const SkillTree = () => {
    return (
        <div className={`${classes['skill-tree-container']} `}>
            <div className="container">
                <div className={`d-flex flex-column justify-content-center align-items-center gap-3`}>
                    <ScrollAnimation animateIn="fadeInUp">
                        <h2>
                            AI that guides you to success
                        </h2>
                        <h3>
                            Elevate your pharmaceutical expertise with our AI-powered learning tools. Monitor your progress, visualize your growth, and achieve your professional goals. Stay motivated and watch your skills flourish in the pharmaceutical industry.
                        </h3>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn="fadeIn" className='w-100'>
                        <div className='d-flex justify-content-around align-items-center my-3'>
                            <div className={`${classes['line-info']} d-xl-flex  gap-3 d-none`}>
                                <div className='d-flex align-items-center gap-2'>
                                    <div className={classes['progress-line']}></div>
                                    <h6>In Progress</h6>
                                </div>
                                <div className='d-flex align-items-center gap-2'>
                                    <div className={classes['pass-line']}></div>
                                    <h6>Pass</h6>
                                </div>
                            </div>
                            <div className={`d-flex justify-content-start align-items-center gap-2 my-2  ${classes["chart-title"]}`}>
                                <UserAvatarIcon />
                                <span>Quality Assurance Specialist</span>
                            </div>
                        </div>
                        <div className={`${classes['category-wrapper']} d-flex flex-column align-items-center`}>
                            <ScrollAnimation animateIn="fadeIn">
                                <div className={classes['category']}>
                                    <h6>
                                        Quality Control
                                    </h6>
                                </div>
                            </ScrollAnimation>
                            <ScrollAnimation animateIn="fadeIn" delay={300}>
                                <div className='d-flex mt-0 mt-md-3'>
                                    <div className={`${classes['category']} ${classes['category-2']}`}>
                                        <h6>
                                            Professional Development
                                        </h6>
                                    </div>
                                    <div className={`${classes['category']} ${classes['category-3']}`}>
                                        <h6>
                                            Quality Assurance
                                        </h6>
                                    </div>
                                </div>
                            </ScrollAnimation>
                            <ScrollAnimation animateIn="fadeIn" delay={600}>
                                <div className='d-flex mt-md-5 mt-3'>
                                    <div className={`${classes['category']} ${classes['category-4']}`}>
                                        <h6>
                                            IT Solutions
                                        </h6>
                                    </div>
                                    <div className={`${classes['category']} ${classes['category-5']}`}>
                                        <h6>
                                            Manufacturing
                                        </h6>
                                    </div>
                                </div>
                            </ScrollAnimation>
                            <ScrollAnimation animateIn="fadeIn" delay={800}>
                                <div className='d-flex mt-md-5 mt-3 mb-md-3 mb-1'>
                                    <div className={`${classes['category']} ${classes['category-2']}`}>
                                        <h6>
                                            Marketing and Sales
                                        </h6>
                                    </div>
                                    <div className={`${classes['category']} ${classes['category-3']}`}>
                                        <h6>
                                            Supply Chain Management
                                        </h6>
                                    </div>
                                </div>
                            </ScrollAnimation>
                            <ScrollAnimation animateIn="fadeIn" delay={800}>
                                <div className={classes['category']}>
                                    <h6>
                                        Regulatory Affairs
                                    </h6>
                                </div>
                            </ScrollAnimation>
                            <img className={classes['skill-tree-image']} src={SkillTreeImage.src} alt="Pharmuni Interactive Skill Tree for Building a Successful Pharmaceutical Career" />
                        </div>
                    </ScrollAnimation>
                </div>
            </div>
        </div>
    );
};

export default SkillTree;