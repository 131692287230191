import React from 'react';

import LinkButton from '@core/components/linkbutton/LinkButton';

import TrustPilotStars from '@core/assets/images/stars-4.5.svg';
import TrustPilotLogo from '@core/assets/images/Trustpilot_Logo.png';

import classes from './TrustPilot.module.scss';


const TrustPilot = () => {

    return (

        <div className={classes['wrapper']}>
            <LinkButton kind='simple' href={'https://www.trustpilot.com/review/pharmuni.com'}>
                <div className={`d-flex flex-wrap justify-content-center  gap-3`}>
                    <img src={TrustPilotLogo.src} alt='Trustpilot Logo – Endorsing Pharmuni’s Trusted Pharmaceutical Education Platform' />
                    <div className='d-flex flex-column align-items-start gap-1'>
                        <TrustPilotStars />
                        <p>
                            Trust Score<strong> 4.5</strong>
                        </p>
                    </div>
                    <span>Excellent</span>
                </div>
            </LinkButton>
        </div>

    );
};

export default TrustPilot;