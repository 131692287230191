import { useRef, useCallback } from 'react';

import LinkButton from '@core/components/linkbutton/LinkButton';

import { ArrowRight, ArrowLeft, ArrowRight2, ArrowRight3 } from 'iconsax-react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useSwiper } from 'swiper/react';

import PharmuniForLearnersImg from 'assets/images/pharmuni-for-learners.png';
import PharmuniForBusinessImg from 'assets/images/pharmuni-for-business.png';
import PharmuniForUniImg from 'assets/images/pharmuni-for-university.png';

import classes from './LearnersBusinessesUniversities.module.scss';
import ActionButton from '@core/components/actionbutton/ActionButton';


const LearnersBusinessesUniversities = () => {
	const sliderRef = useRef(null);
	const handlePrev = useCallback(() => {
		if (!sliderRef.current) return;
		sliderRef.current.swiper.slidePrev();
	}, []);
	const handleNext = useCallback(() => {
		if (!sliderRef.current) return;
		sliderRef.current.swiper.slideNext();
	}, []);
	return (
		<div className={`${classes['section-wrapper']} d-none d-lg-block`}>
			<div className="container">
				<div className='row'>
					<div className="col-1 d-flex flex-column justify-content-center">
						<ActionButton onClick={handlePrev}><ArrowLeft /></ActionButton>
					</div>
					<div className="col-10">
						<Swiper ref={sliderRef} loop={true}>
							<SwiperSlide>
								<div className={classes['slide-wrapper']}>
									<div className='row'>
										<div className='col-xl-5 col-12 '>
											<img
												className=""
												src={PharmuniForLearnersImg.src}
												alt="Pharmuni for Learners – Bridging Theory and Practice for Pharma Career Success"
											/>
										</div>
										<div className='col-xl-7 col-12 d-flex flex-column justify-content-center'>
											<h3 className={classes['individual']}>
												For <br /> Individuals
											</h3>
											<h4>
												Unlock Your Career Potential
											</h4>
											<p>
												Advance with Pharmuni's GxP courses designed for both novices and seasoned professionals.
											</p>
											<div className='d-flex justify-content-end'>
												<LinkButton kind="secondary-simple" href={'https://pharmuni.com/our-impact'}>
													Learn more<ArrowRight2 />
												</LinkButton>
											</div>
										</div>
									</div>
								</div>
							</SwiperSlide>
							<SwiperSlide>
								<div className={classes['slide-wrapper']}>
									<div className='row'>
										<div className='col-xl-5 col-12'>
											<img
												className=""
												src={PharmuniForBusinessImg.src}
												alt="Pharmuni for Business – Innovative Training Solutions for Pharma Industry Leaders"
											/>
										</div>
										<div className='col-xl-7 col-12 d-flex flex-column justify-content-center'>
											<h3 className={classes['business']}>
												For <br /> Businesses
											</h3>
											<h4>
												Attract Top Talent Fast
											</h4>
											<p>
												Enhance your recruitment with Pharmuni Business and secure industry-leading professionals quickly.
											</p>
											<div className='d-flex justify-content-end'>
												<LinkButton kind="secondary-simple" href={'https://pharmuni.com/career-hub/'}>
													Learn more<ArrowRight2 />
												</LinkButton>
											</div>
										</div>
									</div>
								</div>
							</SwiperSlide>
							<SwiperSlide>
								<div className={classes['slide-wrapper']}>
									<div className='row'>
										<div className='col-xl-5 col-12'>
											<img
												className=""
												src={PharmuniForUniImg.src}
												alt="Pharmuni for University – Advancing Research & Academic Excellence in Pharmaceuticals"
											/>
										</div>
										<div className='col-xl-7 col-12 d-flex flex-column justify-content-center'>
											<h3 className={classes['uni']}>
												For <br /> Universities
											</h3>
											<h4>
												Prepare Your Students for Success
											</h4>
											<p>
												Partner with Pharmuni for an enriched curriculum, ISO-certified qualifications, and a dedicated job board to prepare your students for success in the pharmaceutical industry.
											</p>
											<div className='d-flex justify-content-end'>
												<LinkButton kind="secondary-simple" href={'https://pharmuni.com/universities/'}>
													Learn more<ArrowRight2 />
												</LinkButton>
											</div>
										</div>
									</div>
								</div>
							</SwiperSlide>
						</Swiper>
					</div>
					<div className="col-1 d-flex flex-column justify-content-center">
						<ActionButton onClick={handleNext}><ArrowRight /></ActionButton>
					</div>
				</div>
			</div>
		</div>
	);
};

export default LearnersBusinessesUniversities;
