import React from 'react';

import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';

import LinkButton from '../linkbutton/LinkButton';

import classes from './DashboardCourseCard.module.scss';
import StatusBadge from './statusbadge/StatusBadge';



const DashboardCourseCard = ({ data }) => {
    const getButtonText = () => {
        switch (data.status) {
            case 'progressing':
                return 'Continue Learning';
            case 'quiz':
                return 'Take the Quiz';
            case 'completed':
                return 'Receive Certificate';
            default:
                return 'Start Learning';
        }
    };
    return (
        <div className={classes.wrapper} style={{ backgroundImage: `url(${data.image})` }}>
            <div className={classes.content}>
                <h3>
                    {data.title}
                </h3>                
                <div className={classes['progress-wrapper']}>
                    {(data.status == 'quiz' || data.status == 'completed') && (
                        <StatusBadge status={data.status} />
                    )}
                    {data.progress_percentage && data.status === 'progressing' && (
                        <Box className='mb-3 w-100'>
                            <span>{data.progress_percentage}% Completed</span>
                            <LinearProgress
                                variant="determinate"
                                value={data.progress_percentage}
                                sx={{
                                    borderRadius: '5px', // Adjust border radius
                                }}
                            />
                        </Box>
                    )}
                </div>

                <LinkButton kind='primary-color' to={`/courses/${data.id}/${data.slug}`}>
                    {getButtonText()}
                </LinkButton>
            </div>
        </div>
    );
};

export default DashboardCourseCard;