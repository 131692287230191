import React from 'react';

import TickIcon from 'assets/icons/tick-blank.svg';

import { Timer1 } from 'iconsax-react';

import classes from './StatusBadge.module.scss';

const StatusBadge = ({ status }) => {
    switch (status) {
        case "quiz":
            return (
                <div className={classes.awaiting}>
                    <Timer1 size={16} variant='TwoTone' />
                    Awaiting Quiz
                </div>
            );
            break;
        case "completed":
            return (
                <div className={classes.completed}>
                    <TickIcon />
                    Completed
                </div>
            );
            break;
        default:
            return (<></>);
    }

};

export default StatusBadge;