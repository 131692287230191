import React, { useState } from 'react';
import useWindowSize from 'hooks/use-windowSize';

import { KnowPharmuniItems } from './know-pharmuni.const.js';
import { Button, IconButton } from '@mui/material';

import { Book1, Bookmark2, Book, Notepad2, Note, ArrowRight2, ChartCircle } from 'iconsax-react';

import ImageChanger from './ImageChanger/ImageChanger.jsx';
import LinkButton from '@core/components/linkbutton/LinkButton.jsx';

import classes from './KnowPharmuni.module.scss';




const KnowPharmuni = () => {
    const [selectedItem, setSelectedItem] = useState(KnowPharmuniItems[0].id);
    const { desktopDevice } = useWindowSize();
    return (
        <div className='container mb-5'>
            <div className={classes.wrapper}>
                <div className='row'>
                    <div className='col-12 pe-5'>
                        <h3>Get to know Pharmuni</h3>
                        <h2> {KnowPharmuniItems.find(item => item.id === selectedItem)?.title}</h2>
                        <p>
                            {KnowPharmuniItems.find(item => item.id === selectedItem)?.text}
                        </p>
                    </div>
                </div>
                <div className='row mt-4 mb-5'>
                    <div className='col-xl-4 col-12 order-xl-1 order-2'>
                        <div className={classes['button-list']}>
                            <Button
                                className={selectedItem === 'skill_tree' ? classes['selected'] : ''}
                                onClick={() => setSelectedItem('skill_tree')}
                            >
                                <ChartCircle color={selectedItem === 'skill_tree' ? '#18BBA8' : 'white'} variant="TwoTone" />
                                Skill Tree
                            </Button>
                            <Button
                                className={selectedItem === 'courses' ? classes['selected'] : ''}
                                onClick={() => setSelectedItem('courses')}
                            >
                                <Book1 color={selectedItem === 'courses' ? '#18BBA8' : 'white'} variant="TwoTone" />
                                Courses
                            </Button>
                            <Button
                                className={selectedItem === 'certificates' ? classes['selected'] : ''}
                                onClick={() => setSelectedItem('certificates')}
                            >
                                <Bookmark2 color={selectedItem === 'certificates' ? '#18BBA8' : 'white'} variant="TwoTone" />
                                Certificates
                            </Button>
                            <Button
                                className={selectedItem === 'resume' ? classes['selected'] : ''}
                                onClick={() => setSelectedItem('resume')}
                            >
                                <Book color={selectedItem === 'resume' ? '#18BBA8' : 'white'} variant="TwoTone" />
                                Resume Builder
                            </Button>
                            <Button
                                className={selectedItem === 'cover_letter' ? classes['selected'] : ''}
                                onClick={() => setSelectedItem('cover_letter')}
                            >
                                <Notepad2 color={selectedItem === 'cover_letter' ? '#18BBA8' : 'white'} variant="TwoTone" />
                                Cover Letters Builder
                            </Button>
                            <Button
                                className={selectedItem === 'consultation' ? classes['selected'] : ''}
                                onClick={() => setSelectedItem('consultation')}
                            >
                                <Note color={selectedItem === 'consultation' ? '#18BBA8' : 'white'} variant="TwoTone" />
                                VIP Coaching
                            </Button>
                        </div>
                    </div>
                    <div className='col-xl-8 col-12 order-xl-2 order-1 d-flex flex-column align-items-end justify-content-end'>
                        {desktopDevice ? (
                            <ImageChanger
                                firstImage={KnowPharmuniItems.find(item => item.id === selectedItem).image}
                                secondImage={KnowPharmuniItems.find(item => item.id === selectedItem).secondImage}
                                altText={KnowPharmuniItems.find(item => item.id === selectedItem).altText}
                            />
                        ) : (
                            <ImageChanger
                                firstImage={KnowPharmuniItems.find(item => item.id === selectedItem).mobileImage}
                                secondImage={KnowPharmuniItems.find(item => item.id === selectedItem).secondMobileImage}
                                altText={KnowPharmuniItems.find(item => item.id === selectedItem).altText}
                            />
                        )}
                        <div className={classes['lear-more-wrapper']}>
                            <div>
                                <LinkButton
                                    kind='secondary-simple'
                                    href={KnowPharmuniItems.find(item => item.id === selectedItem).link}
                                >
                                    Learn more
                                    <ArrowRight2 size={18} />
                                </LinkButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default KnowPharmuni;