import { Fragment } from 'react';

import CartCarousel from '@core/components/cartcarousel/CartCarousel';
import CourseCard from './CourseCard';
import LectureCard from './LectureCard';
import VerticalCard from './VerticalCard';
import DashboardCourseCard from '@core/components/dashboardcoursecard/DashboardCourseCard';

import classes from './Content.module.scss';

const Content = ({ section, origin }) => {
	if (section.results.length === 0) return null;
	return (
		<CartCarousel section={section} origin={origin}>
			{section.results.map((content) => (
				<Fragment key={content.id + content.type}>
					{section.view_type === 'vertical-content' ? (
						<div className={classes['card-wrapper-vertical']}>
							{/* <VerticalCard card={content} /> */}
							<DashboardCourseCard data={content} />
						</div>
					) : (
						<div className={`${classes['wrapper']} d-flex align-items-stretch`}>
							{content.type === 'course' ? (
								<CourseCard card={content} className="home-page" />
							) : (
								<DashboardCourseCard data={content} />
								// <LectureCard card={content} />
							)}
						</div>
					)}
				</Fragment>
			))}
		</CartCarousel>
	);
};

export default Content;
